import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// project imports
import { useEffect, useState } from 'react';
import { LOGOUT } from 'store/actions';
import configData from 'config';

// third party
import axios from 'axios';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const account = useSelector((state) => state.account);
    const { isLoggedIn } = account;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [tokenValid, setTokenValid] = useState(true);

    useEffect(() => {
        let abortController;
        (async () => {
            abortController = new AbortController();
            const signal = abortController.signal;

            await axios
                .post(
                    `${configData.apiServer}users/checkSession`,
                    {},
                    {
                        headers: { accept: 'application/json', Authorization: account.token }
                    },
                    { signal }
                )
                .then((response) => setTokenValid(response?.data.success))
                .catch((error) => setTokenValid(error.status));
        })();

        return () => abortController.abort();
    }, [account]);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/', { replace: true });
        }

        if (!tokenValid) {
            navigate('/', { replace: true });
            dispatch({ type: LOGOUT });
        }
    }, [isLoggedIn, navigate, tokenValid, dispatch]);

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
