import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// dashboard routing
const DashboardOverview = Loadable(lazy(() => import('views/dashboard/Overview')));
const DashboardSingleAsset = Loadable(lazy(() => import('views/dashboard/SingleAsset')));
const DashboardMarket = Loadable(lazy(() => import('views/dashboard/MarketConditions')));
const AppUserSocialProfile = Loadable(lazy(() => import('views/profile-setting')));

// ==============================|| MAIN ROUTING ||============================== //

const UserRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '*',
            element: <DashboardOverview />
        },
        {
            path: '/overview',
            element: <DashboardOverview />
        },
        {
            path: '/single-asset/:tab',
            element: <DashboardSingleAsset />
        },
        {
            path: '/market',
            element: <DashboardMarket />
        },
        {
            path: '/user/social-profile/:tab',
            element: <AppUserSocialProfile />
        }
    ]
};

export default UserRoutes;
