// project imports
import dashboard from './dashboard';

// ==============================|| MENU ITEMS ||============================== //

const userMenuItems = {
    items: [dashboard]
};

export default userMenuItems;
