// material-ui
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';

// project imports
import NavGroup from './NavGroup';
import adminNavigation from 'menu-items/admin-menu-items';
import userNavigation from 'menu-items/user-menu-items';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const account = useSelector((state) => state.account);
    const admin = account.user?.admin_status || false;

    let menuItem = userNavigation;

    if (admin === 'admin' || admin === 'super_admin') {
        menuItem = adminNavigation;
    }

    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
