import PropTypes from 'prop-types';
import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Avatar,
    Box,
    ButtonBase,
    Card,
    Grid,
    InputAdornment,
    OutlinedInput,
    Popper,
    ListItemButton,
    ListItemText,
    Typography
} from '@mui/material';

// third-party
import PopupState, { bindPopper, bindToggle } from 'material-ui-popup-state';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';

// assets
import { IconSearch, IconX } from '@tabler/icons';
import { shouldForwardProp } from '@mui/system';

// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
    zIndex: 1100,
    width: '99%',
    top: '-55px !important',
    padding: '0 12px',
    [theme.breakpoints.down('sm')]: {
        padding: '0 10px'
    }
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(({ theme }) => ({
    width: 434,
    marginLeft: 16,
    paddingLeft: 16,
    paddingRight: 16,
    '& input': {
        background: 'transparent !important',
        paddingLeft: '4px !important'
    },
    [theme.breakpoints.down('lg')]: {
        width: 250
    },
    [theme.breakpoints.down('md')]: {
        width: '100%',
        marginLeft: 4,
        background: theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff'
    }
}));

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.colors.Lblue25,
    color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.colors.Frame2,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.colors.Lblue50
    }
}));

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({ value, setValue, popupState }) => {
    const theme = useTheme();

    return (
        <OutlineInputStyle
            id="input-search-header"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Search"
            startAdornment={
                <InputAdornment position="start">
                    <IconSearch stroke={1.5} size="1rem" color={theme.palette.colors.Frame2} />
                </InputAdornment>
            }
            endAdornment={
                <InputAdornment position="end">
                    {/* <ButtonBase sx={{ borderRadius: '12px' }}>
                        <HeaderAvatarStyle variant="rounded">
                            <IconAdjustmentsHorizontal stroke={1.5} size="1.3rem" />
                        </HeaderAvatarStyle>
                    </ButtonBase> */}
                    <Box sx={{ ml: 2 }}>
                        <ButtonBase sx={{ borderRadius: '12px' }}>
                            <Avatar
                                variant="rounded"
                                sx={{
                                    ...theme.typography.commonAvatar,
                                    ...theme.typography.mediumAvatar,
                                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light,
                                    color: theme.palette.colors.White,
                                    '&:hover': {
                                        background: theme.palette.orange.dark,
                                        color: theme.palette.orange.light
                                    }
                                }}
                                {...bindToggle(popupState)}
                            >
                                <IconX stroke={1.5} size="1.3rem" />
                            </Avatar>
                        </ButtonBase>
                    </Box>
                </InputAdornment>
            }
            aria-describedby="search-helper-text"
            inputProps={{ 'aria-label': 'weight' }}
        />
    );
};

MobileSearch.propTypes = {
    value: PropTypes.string,
    setValue: PropTypes.func,
    popupState: PopupState
};

// ==============================|| SEARCH INPUT ||============================== //

const SearchSection = () => {
    const theme = useTheme();
    const [value, setValue] = useState('');
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState('');
    const [searchRows, setSearchRows] = useState([]);
    // const navigate = useNavigate();

    const pages = [
        {
            name: 'Portfolio Overview',
            string:
                'total capacity inverter battery discharge today daily p&l vs. budget daily monthly ytd asset name technology iso battery capacity inverter capacity cycles used / cycles asllowed discharge today daily pn budget ytd pnl budget',
            route: '/overview'
        },
        {
            name: 'Asset Overview',
            string:
                'current dispatch price of dispatch real time energy price da energy price production health market conditions profit/loss',
            route: '/single-asset/overview'
        },
        {
            name: 'Health',
            string:
                'cycle count discharged energy inverter capacity inverter total capacity average availability battery capacity battery total capacity battery average capacity # of hours spent charging # of hours spent discharging average state of charge additional ltsa cost cycles state of charge',
            route: '/single-asset/health'
        },
        {
            name: 'Profit Loss',
            string:
                'actual p&l da rolling 3 day average da yesterday rt yesterday perfect foresight revenue by product revenue v budget p&l components',
            route: '/single-asset/profit-loss'
        },
        {
            name: 'Market Conditions',
            string:
                'price map real time day ahead generation mix fuel type renewables system demand reg. down reg. up non spin todays snapshot capacity available forecast peak demand surplus capacity yesterday peak demand tomorrow peak demand',
            route: '/single-asset/market-conditions'
        },
        {
            name: 'Schedule',
            string: 'schedule',
            route: '/single-asset/schedule'
        },
        {
            name: 'ISO Market Conditions',
            string:
                'price map real time day ahead generation mix fuel type renewables system demand reg. down reg. up non spin todays snapshot capacity available forecast peak demand surplus capacity yesterday peak demand tomorrow peak demand',
            route: '/market'
        }
    ];

    const handleSearch = (event) => {
        const newString = event?.target.value;
        setValue(newString || '');

        if (newString) {
            setAnchorEl(event.currentTarget);
            setOpen(true);

            const newRows = pages.filter((page) => {
                let matches = true;
                let containsQuery = false;

                if (page.string.toString().toLowerCase().includes(newString.toString().toLowerCase())) {
                    containsQuery = true;
                }

                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
            setSearchRows(newRows);
        }
    };

    const handleClick = (route) => {
        setOpen(false);
        // navigate(route, { replace: true });
        // window.open(route, '_blank');
        window.location.href = route;
    };

    return (
        <>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <PopupState variant="popper" popupId="demo-popup-popper">
                    {(popupState) => (
                        <>
                            <Box sx={{ ml: 2 }}>
                                <ButtonBase sx={{ borderRadius: '12px' }}>
                                    <HeaderAvatarStyle variant="rounded" {...bindToggle(popupState)}>
                                        <IconSearch
                                            stroke={1.5}
                                            size="1.2rem"
                                            color={theme.palette.mode === 'dark' ? theme.palette.colors.White : theme.palette.colors.Gray3}
                                        />
                                    </HeaderAvatarStyle>
                                </ButtonBase>
                            </Box>
                            <PopperStyle {...bindPopper(popupState)} transition>
                                {({ TransitionProps }) => (
                                    <>
                                        <Transitions type="zoom" {...TransitionProps} sx={{ transformOrigin: 'center left' }}>
                                            <Card
                                                sx={{
                                                    background: theme.palette.mode === 'dark' ? theme.palette.dark[900] : '#fff',
                                                    [theme.breakpoints.down('sm')]: {
                                                        border: 0,
                                                        boxShadow: 'none'
                                                    }
                                                }}
                                            >
                                                <Box sx={{ p: 2 }}>
                                                    <Grid container alignItems="center" justifyContent="space-between">
                                                        <Grid item xs>
                                                            <MobileSearch value={value} setValue={setValue} popupState={popupState} />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Card>
                                        </Transitions>
                                    </>
                                )}
                            </PopperStyle>
                        </>
                    )}
                </PopupState>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <OutlineInputStyle
                    id="input-search-header"
                    value={value}
                    onChange={handleSearch}
                    placeholder="Search"
                    startAdornment={
                        <InputAdornment position="start">
                            <IconSearch
                                stroke={2.5}
                                size="1rem"
                                color={theme.palette.mode === 'dark' ? theme.palette.colors.White : theme.palette.colors.Frame2}
                            />
                        </InputAdornment>
                    }
                    // endAdornment={
                    //     <InputAdornment position="end">
                    //         <ButtonBase sx={{ borderRadius: '12px' }}>
                    //             <HeaderAvatarStyle variant="rounded">
                    //                 <IconAdjustmentsHorizontal stroke={1.5} size="1.3rem" />
                    //             </HeaderAvatarStyle>
                    //         </ButtonBase>
                    //     </InputAdornment>
                    // }
                    aria-describedby="search-helper-text"
                    inputProps={{ 'aria-label': 'weight' }}
                />
            </Box>
            {value && (
                <Popper
                    className="popper-root"
                    open={open}
                    anchorEl={anchorEl}
                    placement="bottom-start"
                    disablePortal
                    popperOptions={{
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 0]
                                }
                            }
                        ]
                    }}
                >
                    <MainCard
                        border={false}
                        content={false}
                        sx={{ backgroundColor: theme.palette.mode === 'dark' ? theme.palette.colors.Frame2 : theme.palette.colors.Lblue10 }}
                        elevation={16}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {searchRows.map((row) => (
                                    <ListItemButton key={row.name} onClick={() => handleClick(row.route)}>
                                        <ListItemText
                                            primary={
                                                <Grid container>
                                                    <Typography color="textPrimary">{row.name}</Typography>
                                                </Grid>
                                            }
                                        />
                                    </ListItemButton>
                                ))}
                            </Grid>
                        </Grid>
                    </MainCard>
                </Popper>
            )}
        </>
    );
};

export default SearchSection;
