import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, FormControl, FormControlLabel, Switch } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import { MENU_TYPE } from 'store/actions';

// assets
import { IconMenu2 } from '@tabler/icons';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);

    // state - layout type
    const [navType, setNavType] = useState(
        window.localStorage.getItem('navType') === 'dark' ? window.localStorage.getItem('navType') : customization.navType
    );
    useEffect(() => {
        dispatch({ type: MENU_TYPE, navType });
        window.localStorage.setItem('navType', navType);
    }, [dispatch, navType]);

    const handleDarkLightSwitchButton = (e) => {
        if (e.target.checked) {
            setNavType('dark');
        } else {
            setNavType('light');
        }
    };

    return (
        <>
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center', flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.mode === 'dark' ? theme.palette.colors.DarkBg1 : theme.palette.colors.Lblue25,
                            color: theme.palette.mode === 'dark' ? theme.palette.colors.White : theme.palette.colors.Frame2,
                            '&:hover': {
                                background: theme.palette.mode === 'dark' ? theme.palette.colors.White : theme.palette.colors.Lblue50,
                                color: theme.palette.mode === 'dark' ? theme.palette.colors.DarkBg1 : theme.palette.colors.Frame2
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            <SearchSection />
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            <ProfileSection />

            <FormControl component="fieldset" sx={{ ml: 2 }}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={navType === 'dark'}
                            sx={{
                                border: 1,
                                borderRadius: 13,
                                borderColor: theme.palette.mode === 'dark' ? theme.palette.colors.White : theme.palette.colors.Gray5
                            }}
                            onChange={handleDarkLightSwitchButton}
                        />
                    }
                />
            </FormControl>
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
