/**
 * Color intention that you want to used in your theme
 * @param {JsonObject} theme Theme customization object
 */

export default function themePalette(theme) {
    return {
        mode: theme?.customization?.navType,
        common: {
            black: theme.colors?.darkPaper
        },
        primary: {
            light: theme?.customization?.navType === 'dark' ? theme.colors?.darkPrimaryLight : theme.colors?.primaryLight,
            main: theme?.customization?.navType === 'dark' ? theme.colors?.darkPrimaryMain : theme.colors?.primaryMain,
            dark: theme?.customization?.navType === 'dark' ? theme.colors?.darkPrimaryDark : theme.colors?.primaryDark,
            200: theme?.customization?.navType === 'dark' ? theme.colors?.darkPrimary200 : theme.colors?.primary200,
            800: theme?.customization?.navType === 'dark' ? theme.colors?.darkPrimary800 : theme.colors?.primary800
        },
        secondary: {
            light: theme?.customization?.navType === 'dark' ? theme.colors?.darkSecondaryLight : theme.colors?.secondaryLight,
            main: theme?.customization?.navType === 'dark' ? theme.colors?.darkSecondaryMain : theme.colors?.Coral,
            dark: theme?.customization?.navType === 'dark' ? theme.colors?.darkSecondaryDark : theme.colors?.secondaryDark,
            200: theme?.customization?.navType === 'dark' ? theme.colors?.darkSecondary200 : theme.colors?.secondary200,
            800: theme?.customization?.navType === 'dark' ? theme.colors?.darkSecondary800 : theme.colors?.secondary800
        },
        error: {
            light: theme.colors?.errorLight,
            main: theme.colors?.errorMain,
            dark: theme.colors?.errorDark
        },
        orange: {
            light: theme.colors?.orangeLight,
            main: theme.colors?.orangeMain,
            dark: theme.colors?.orangeDark
        },
        warning: {
            light: theme.colors?.warningLight,
            main: theme.colors?.warningMain,
            dark: theme.colors?.warningDark
        },
        success: {
            light: theme.colors?.successLight,
            200: theme.colors?.success200,
            main: theme.colors?.successMain,
            dark: theme.colors?.successDark
        },
        grey: {
            50: theme.colors?.grey50,
            100: theme.colors?.grey100,
            500: theme.darkTextSecondary,
            600: theme.heading,
            700: theme.darkTextPrimary,
            900: theme.textDark
        },
        dark: {
            light: theme.colors?.darkTextPrimary,
            main: theme.colors?.darkLevel1,
            dark: theme.colors?.darkLevel2,
            800: theme.colors?.darkBackground,
            900: theme.colors?.darkPaper
        },
        text: {
            primary: theme.darkTextPrimary,
            secondary: theme.darkTextSecondary,
            dark: theme.textDark,
            hint: theme.colors?.grey100
        },
        background: {
            light: theme.colors?.primaryLight10,
            light70: theme.colors?.primaryLight70,
            paper: theme.paper,
            default: theme.backgroundDefault
        },
        auth: {
            light: theme?.customization?.navType === 'dark' ? theme.colors?.darkPrimaryLight : theme.colors?.primaryLight,
            main: theme?.customization?.navType === 'dark' ? theme.colors?.darkPrimaryMain : theme.colors?.primaryDark,
            dark: theme?.customization?.navType === 'dark' ? theme.colors?.darkPrimaryDark : theme.colors?.primaryMain,
            200: theme?.customization?.navType === 'dark' ? theme.colors?.darkPrimary200 : theme.colors?.primary200,
            800: theme?.customization?.navType === 'dark' ? theme.colors?.darkPrimary800 : theme.colors?.primary800
        },
        /**
         * Above this line was already in the template we used.
         * We didn't touch it since they are used in built in features
         * Most of the dashboard colors are fed from below
         */
        colors: {
            CCGreen: theme.colors?.CCGreen,
            LightGreen: theme.colors?.LightGreen,
            YellowishGreen: theme.colors?.YellowishGreen,
            LightBlue: theme.colors?.LightBlue,
            DarkBlue: theme.colors?.DarkBlue,
            Coral: theme.colors?.Coral,
            DarkCoral: theme.colors?.DarkCoral,
            MediumBlue: theme.colors?.MediumBlue,
            DarkBg1: theme.colors?.DarkBg1,
            DarkBg2: theme.colors?.DarkBg2,
            Frame1: theme.colors?.Frame1,
            Frame2: theme.colors?.Frame2,
            White: theme.colors?.White,
            MediumBluewithWhite: theme.colors?.MediumBluewithWhite,
            CardBlue: theme.colors?.CardBlue,
            Lblue10: theme.colors?.Lblue10,
            Lblue25: theme.colors?.Lblue25,
            Lblue50: theme.colors?.Lblue50,
            Dblue25: theme.colors?.Dblue25,
            Dblue50: theme.colors?.Dblue50,
            Dblue75: theme.colors?.Dblue75,
            Lgreen25: theme.colors?.Lgreen25,
            Lgreen50: theme.colors?.Lgreen50,
            CCgreen50: theme.colors?.CCgreen50,
            Coral50: theme.colors?.Coral50,
            Warning: theme.colors?.Warning,
            Success: theme.colors?.Success,
            Danger: theme.colors?.Danger,
            Info: theme.colors?.Info,
            GrayVariant: theme.colors?.GrayVariant,
            CCGray: theme.colors?.CCGray,
            Gray1: theme.colors?.Gray1,
            Gray2: theme.colors?.Gray2,
            Gray3: theme.colors?.Gray3,
            Gray4: theme.colors?.Gray4,
            Gray5: theme.colors?.Gray5,
            Gray6: theme.colors?.Gray6
        }
    };
}
