export default function componentStyleOverrides(theme) {
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    borderRadius: '10px'
                },
                contained: {
                    color: theme.colors.White
                },
                outlined: {
                    color: theme.colors.MediumBlue
                }
            }
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
                root: {
                    backgroundImage: 'none'
                },
                rounded: {
                    borderRadius: `${theme?.customization?.borderRadius}px`
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: theme.colors?.Frame2,
                    padding: '24px'
                },
                title: {
                    fontSize: '1.125rem'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    alignItems: 'center'
                },
                outlined: {
                    border: '1px dashed'
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    color: theme?.customization?.navType === 'dark' ? theme.colors.White : theme.colors.Gray3,
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    '&.Mui-selected': {
                        color: theme.colors.Gray2,
                        backgroundColor: theme.colors.Lblue10,
                        '&:hover': {
                            backgroundColor: theme.colors.Lblue10
                        },
                        '& .MuiListItemIcon-root': {
                            color: theme.colors.Gray2
                        }
                    },
                    '&:hover': {
                        backgroundColor: theme.colors.Lblue10,
                        color: theme.colors.Gray3,
                        '& .MuiListItemIcon-root': {
                            color: theme.colors.Gray2
                        }
                    }
                }
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    height: 5,
                    border: 0,
                    borderRadius: 6,
                    color: theme.colors.White,
                    backgroundColor: theme?.customization?.navType === 'dark' ? theme.colors.MediumBlue : theme.colors.Gray4,
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    '&.Mui-selected': {
                        color: theme.colors.White,
                        backgroundColor: theme?.customization?.navType === 'dark' ? theme.colors.DarkBlue : theme.colors.MediumBlue,
                        '&:hover': {
                            color: theme.colors.White,
                            backgroundColor: theme.colors.MediumBlue
                        },
                        '& .MuiToggleButton-root': {
                            borderRadius: 10
                        }
                    },
                    '&:hover': {
                        backgroundColor: theme.colors.Lblue10,
                        color: theme.colors.Gray3
                    }
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: theme?.customization?.navType === 'dark' ? theme.colors.White : theme.colors.Frame2,
                    minWidth: '36px'
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: theme?.customization?.navType === 'dark' ? theme.colors.White : theme.colors.Frame2
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: theme?.customization?.navType === 'dark' ? theme.colors.White : theme.colors.Gray3,
                    '&::placeholder': {
                        color: theme?.customization?.navType === 'dark' ? theme.colors.White : theme.colors.Gray3,
                        fontSize: '0.875rem'
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: theme?.customization?.navType === 'dark' ? theme.colors.DarkBg1 : theme.colors.Gray6,
                    borderRadius: `${theme?.customization?.borderRadius}px`,
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.colors?.Gray5
                    },
                    '&:hover $notchedOutline': {
                        borderColor: theme.colors?.CCGreen
                    },
                    '&.MuiInputBase-multiline': {
                        padding: 1
                    }
                },
                input: {
                    fontWeight: 500,
                    background: theme?.customization?.navType === 'dark' ? theme.colors.DarkBg1 : theme.colors.Gray6,
                    padding: '15.5px 14px',
                    borderRadius: `${theme?.customization?.borderRadius}px`,
                    '&.MuiInputBase-inputSizeSmall': {
                        padding: '10px 14px',
                        '&.MuiInputBase-inputAdornedStart': {
                            paddingLeft: 0
                        }
                    }
                },
                inputAdornedStart: {
                    paddingLeft: 4
                },
                notchedOutline: {
                    borderRadius: `${theme?.customization?.borderRadius}px`
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color: theme?.customization?.navType === 'dark' ? theme.colors.darkTextPrimary + 50 : theme.colors?.grey300
                    }
                },
                mark: {
                    backgroundColor: theme.paper,
                    width: '4px'
                },
                valueLabel: {
                    color: theme?.customization?.navType === 'dark' ? theme?.colors?.primaryMain : theme?.colors?.Lblue10
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& .MuiAutocomplete-tag': {
                        background: theme?.customization?.navType === 'dark' ? theme.colors.darkTextPrimary + 20 : theme.colors?.Lblue10,
                        borderRadius: 4,
                        color: theme.textDark,
                        '.MuiChip-deleteIcon': {
                            color: theme?.customization?.navType === 'dark' ? theme.colors.darkTextPrimary + 80 : theme.colors?.Lblue10
                        }
                    }
                },
                popper: {
                    borderRadius: `${theme?.customization?.borderRadius}px`,
                    boxShadow: '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)'
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.colors.Gray3,
                    opacity: theme?.customization?.navType === 'dark' ? 0.2 : 1
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    '&:focus': {
                        backgroundColor: 'transparent'
                    }
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    /** checked not prop
                     *"&.Mui-checked": {
                     *    fontSize: "28px"
                     *}
                     */
                }
            }
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    width: 42,
                    height: 26,
                    padding: 0,
                    '& .MuiSwitch-switchBase': {
                        padding: 0,
                        margin: 3,
                        transitionDuration: '300ms',
                        '&.Mui-checked': {
                            transform: 'translateX(16px)',
                            '& + .MuiSwitch-track': {
                                backgroundColor: theme.colors.Frame2,
                                opacity: 1
                            }
                        }
                    },
                    '& .MuiSwitch-thumb': {
                        boxSizing: 'border-box',
                        width: 18,
                        height: 18,
                        color: theme?.customization?.navType === 'dark' ? theme.colors.White : theme.colors.MediumBlue
                    },
                    '& .MuiSwitch-track': {
                        borderRadius: 13,
                        backgroundColor: theme.colors.Lblue10,
                        opacity: 1
                    }
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    color: theme?.customization?.navType === 'dark' ? theme.colors?.darkLevel1 : theme.colors.Frame2,
                    background: theme?.customization?.navType === 'dark' ? theme.darkTextPrimary : theme.colors.LGreen25
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '&.MuiChip-deletable .MuiChip-deleteIcon': {
                        color: 'inherit'
                    }
                }
            }
        },
        MuiTimelineContent: {
            styleOverrides: {
                root: {
                    color: theme.colors.Frame2,
                    fontSize: '16px'
                }
            }
        },
        MuiTreeItem: {
            styleOverrides: {
                label: {
                    marginTop: 14,
                    marginBottom: 14
                }
            }
        },
        MuiTimelineDot: {
            styleOverrides: {
                root: {
                    boxShadow: 'none'
                }
            }
        },
        MuiInternalDateTimePickerTabs: {
            styleOverrides: {
                tabs: {
                    backgroundColor: theme?.customization?.navType === 'dark' ? theme.colors?.darkPaper : theme.colors?.LightBlue,
                    '& .MuiTabs-flexContainer': {
                        borderColor:
                            theme?.customization?.navType === 'dark' ? theme.colors?.darkTextPrimary + 20 : theme.colors?.primary200
                    },
                    '& .MuiTab-root': {
                        color: theme?.customization?.navType === 'dark' ? theme.colors?.darkTextSecondary : theme.colors?.grey900
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: theme.colors?.primaryDark
                    },
                    '& .Mui-selected': {
                        color: theme.colors?.LightBlue
                    }
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                flexContainer: {
                    borderBottom: '1px solid',
                    borderColor: theme?.customization?.navType === 'dark' ? theme.colors.darkTextPrimary + 20 : theme.colors?.Frame2
                },
                root: {
                    '& .MuiButtonBase-root': {
                        color: theme?.customization?.navType === 'dark' ? theme.colors?.Gray3 : theme.colors?.Frame2
                    }
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    padding: '12px 0 12px 0'
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: 'none',
                    borderColor: theme?.customization?.navType === 'dark' ? theme.colors.darkTextPrimary + 15 : theme.colors?.Gray6,
                    '&.MuiTableCell-head': {
                        fontSize: '0.875rem',
                        color: theme?.customization?.navType === 'dark' ? theme.colors.White : theme.colors.Frame2,
                        fontWeight: 500
                    },
                    '&.MuiTableCell-body': {
                        fontSize: '0.775rem',
                        color: theme?.customization?.navType === 'dark' ? theme.colors.Gray5 : theme.colors.Frame2,
                        fontWeight: 300
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: theme?.customization?.navType === 'dark' ? theme.colors?.Frame2 : theme.colors.Lblue10,
                    background: theme?.customization?.navType === 'dark' ? theme.colors?.Gray3 : theme.colors?.Gray5
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: '1.25rem'
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                selectLabel: {
                    color: theme?.customization?.navType === 'dark' ? theme.colors?.White : theme.colors.Frame2
                },
                displayedRows: {
                    color: theme?.customization?.navType === 'dark' ? theme.colors?.White : theme.colors.Frame2
                }
            }
        }
    };
}
