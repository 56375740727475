// project imports
import adminPanel from './admin-panel';
import dashboard from './dashboard';

// ==============================|| MENU ITEMS ||============================== //

const adminMenuItems = {
    items: [dashboard, adminPanel]
};

export default adminMenuItems;
