// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CCGreen": "#004310",
	"LightGreen": "#92c79e",
	"YellowishGreen": "#dfe667",
	"LightBlue": "#5ea9a7",
	"DarkBlue": "#20525c",
	"Coral": "#e98682",
	"DarkCoral": "#e5716c",
	"MediumBlue": "#3d939b",
	"DarkBg1": "#111b22",
	"DarkBg2": "#070d12",
	"Frame1": "#18252e",
	"Frame2": "#233745",
	"White": "#ffffff",
	"MediumBluewithWhite": "#8abec3",
	"CardBlue": "#a6cfce",
	"Lblue10": "#eff6f6",
	"Lblue25": "#eff6f6",
	"Lblue50": "#d2e4e4",
	"Dblue25": "#c7d4d6",
	"Dblue50": "#6e878f",
	"Dblue75": "#365964",
	"Lgreen25": "#e4f1e7",
	"Lgreen50": "#c8e3cf",
	"CCgreen50": "#80a187",
	"Coral50": "#f4c2c0",
	"Warning": "#ffc107",
	"Success": "#00c853",
	"Danger": "#d84315",
	"Info": "#2f80ed",
	"GrayVariant": "#a0b1aa",
	"CCGray": "#353535",
	"Gray1": "#333333",
	"Gray2": "#4f4f4f",
	"Gray3": "#828282",
	"Gray4": "#bdbdbd",
	"Gray5": "#e0e0e0",
	"Gray6": "#f8f8f8",
	"paper": "#ffffff",
	"primaryLight10": "rgba(94, 169, 167, 0.062745098)",
	"primaryLight70": "rgba(94, 169, 167, 0.4392156863)",
	"primaryLight": "rgba(94, 169, 167, 0.5647058824)",
	"primary200": "#92c79e",
	"primaryMain": "#5ea9a7",
	"primaryDark": "#20525c",
	"primary800": "#004310",
	"secondaryLight": "#e98682",
	"secondary200": "#dfe667",
	"secondaryMain": "#e5716c",
	"secondaryDark": "#EA8986",
	"secondary800": "#3d939b",
	"successLight": "#edf7ed",
	"success200": "#b6e0b3",
	"successMain": "#6cc067",
	"successDark": "#64ba5f",
	"errorLight": "#e48784",
	"errorMain": "#d9534f",
	"errorDark": "#d54c48",
	"orangeLight": "#fbe9e7",
	"orangeMain": "#ffab91",
	"orangeDark": "#d84315",
	"warningLight": "#fdf5ea",
	"warningMain": "#f0ad4e",
	"warningDark": "#ec9c3d",
	"grey50": "#e0e0e0",
	"grey100": "#c9c9c9",
	"grey200": "#bdbdbd",
	"grey300": "#959595",
	"grey500": "#828282",
	"grey600": "#757575",
	"grey700": "#4f4f4f",
	"grey900": "#333333",
	"darkPaper": "#070d12",
	"darkBackground": "#111b22",
	"darkLevel1": "#18252e",
	"darkLevel2": "#233745",
	"darkTextTitle": "#e4e8f7",
	"darkTextPrimary": "#d5d9e9",
	"darkTextSecondary": "#d8ddf0",
	"darkPrimaryLight": "#5ea9a7",
	"darkPrimaryMain": "#3d939b",
	"darkPrimaryDark": "#20525c",
	"darkPrimary200": "#92c79e",
	"darkPrimary800": "#004310",
	"darkSecondaryLight": "#e98682",
	"darkSecondaryMain": "#e5716c",
	"darkSecondaryDark": "#EA8986",
	"darkSecondary200": "#dfe667",
	"darkSecondary800": "#3d939b"
};
export default ___CSS_LOADER_EXPORT___;
