import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// dashboard routing
const DashboardOverview = Loadable(lazy(() => import('views/dashboard/Overview')));
const DashboardSingleAsset = Loadable(lazy(() => import('views/dashboard/SingleAsset')));
const DashboardMarket = Loadable(lazy(() => import('views/dashboard/MarketConditions')));
const AppUserSocialProfile = Loadable(lazy(() => import('views/profile-setting')));
const AssetsTable = Loadable(lazy(() => import('views/admin-panel/assets')));
const CustomerList = Loadable(lazy(() => import('views/admin-panel/users')));
const GenerateReports = Loadable(lazy(() => import('views/admin-panel/reports')));
const DailyReport = Loadable(lazy(() => import('views/report/daily')));
const WeeklyReport = Loadable(lazy(() => import('views/report/weekly')));
const MonthlyReport = Loadable(lazy(() => import('views/report/monthly')));
// const YearlyReport = Loadable(lazy(() => import('views/report/yearly')));

// ==============================|| MAIN ROUTING ||============================== //

const AdminRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '*',
            element: <DashboardOverview />,
        },
        {
            path: '/overview',
            element: <DashboardOverview />,
        },
        {
            path: '/single-asset/:tab',
            element: <DashboardSingleAsset />,
        },
        {
            path: '/market',
            element: <DashboardMarket />,
        },
        {
            path: '/user/social-profile/:tab',
            element: <AppUserSocialProfile />,
        },
        {
            path: 'assets',
            element: <AssetsTable />,
        },
        {
            path: 'users',
            element: <CustomerList />,
        },
        {
            path: 'generate/reports',
            element: <GenerateReports />,
        },
        {
            path: '/report/daily/:assetId/:isGenerate/:date',
            element: <DailyReport />,
        },
        {
            path: '/report/daily/:assetId/:isGenerate',
            element: <DailyReport />,
        },
        {
            path: '/report/weekly/:assetId/:isGenerate',
            element: <WeeklyReport />,
        },
        {
            path: '/report/monthly/:assetId/:isGenerate',
            element: <MonthlyReport />,
        },
        /**
         * Report pages don't have any navigation button
         * They are spesificly created for another script to render
         * When the script renders these pages the reports are automaticly saved to S3 bucket
         */
        {
            path: '/report/daily/:assetId',
            element: <DailyReport />,
        },
        {
            path: '/report/weekly/:assetId/',
            element: <WeeklyReport />,
        },
        {
            path: '/report/monthly/:assetId/',
            element: <MonthlyReport />,
        },
        // {
        //     path: '/report/yearly/:assetId/',
        //     element: <YearlyReport />
        // }
    ],
};

export default AdminRoutes;
