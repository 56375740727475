// assets
import { IconUsers, IconListDetails, IconReport } from '@tabler/icons';

// constant
const icons = {
    IconUsers,
    IconListDetails,
    IconReport,
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const application = {
    id: 'application',
    title: 'ADMIN PANEL',
    type: 'group',
    children: [
        {
            id: 'customer-list',
            title: 'Users',
            type: 'item',
            url: '/users',
            icon: icons.IconUsers,
            breadcrumbs: false,
        },
        {
            id: 'product-review',
            title: 'Assets',
            type: 'item',
            url: '/assets',
            icon: icons.IconListDetails,
            breadcrumbs: false,
        },
        {
            id: 'generate-reports',
            title: 'Generate Reports',
            type: 'item',
            url: '/generate/reports',
            icon: icons.IconReport,
            breadcrumbs: false,
        },
    ],
};

export default application;
