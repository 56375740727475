import { createTheme } from '@mui/material/styles';

// assets
import colors from 'assets/scss/_themes-vars.module.scss';
import theme1 from 'assets/scss/_theme1.module.scss';

// project imports
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';
import customShadows from './shadows';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = (customization) => {
    let color;
    switch (customization.presetColor) {
        case 'theme1':
            color = theme1;
            break;
        case 'default':
        default:
            color = colors;
    }

    const themeOption = {
        colors: color,
        heading: '',
        paper: '',
        backgroundDefault: '',
        background: '',
        darkTextPrimary: '',
        darkTextSecondary: '',
        textDark: '',
        menuSelected: '',
        menuSelectedBack: '',
        divider: '',
        customization
    };

    switch (customization.navType) {
        case 'dark':
            themeOption.paper = color.Frame1;
            themeOption.backgroundDefault = color.Frame1;
            themeOption.background = color.DarkBg1;
            themeOption.darkTextPrimary = color.White;
            themeOption.darkTextSecondary = color.White;
            themeOption.textDark = color.White;
            themeOption.menuSelected = color.Lblue50;
            themeOption.menuSelectedBack = color.Lblue10 + 15;
            themeOption.divider = color.Frame2;
            themeOption.heading = color.Frame2;
            break;
        case 'light':
        default:
            themeOption.paper = color.White;
            themeOption.backgroundDefault = color.White;
            themeOption.background = color.Lblue10;
            themeOption.darkTextPrimary = color.Frame2;
            themeOption.darkTextSecondary = color.Gray2;
            themeOption.textDark = color.Frame2;
            themeOption.menuSelected = color.Lblue50;
            themeOption.menuSelectedBack = color.Lblue10;
            themeOption.divider = color.Gray3;
            themeOption.heading = color.Gray6;
            break;
    }

    const themeOptions = {
        palette: themePalette(themeOption),
        mixins: {
            toolbar: {
                minHeight: '48px',
                padding: '16px',
                '@media (min-width: 600px)': {
                    minHeight: '48px'
                }
            }
        },
        typography: themeTypography(themeOption),
        customShadows: customShadows(customization.navType, themeOption)
    };

    const themes = createTheme(themeOptions);
    themes.components = componentStyleOverrides(themeOption);

    return themes;
};

export default theme;
