const config = {
    basename: '',
    defaultPath: '/overview',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    theme: 'light', // light, dark
    presetColor: 'theme1',
    apiServer: 'https://dbbackendapi.caeruscommodities.com/api/'
};

export default config;
