import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';

// routes
import LoginRoutes from './LoginRoutes';
import UserRoutes from './UserRoutes';
import AdminRoutes from './AdminRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const account = useSelector((state) => state.account);

    const admin = account.user?.admin_status || false;
    let routes = [];

    if (admin === 'admin' || admin === 'super_admin') {
        routes = [LoginRoutes, AdminRoutes];
    } else {
        routes = [LoginRoutes, UserRoutes];
    }
    return useRoutes(routes);
}
