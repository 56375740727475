// assets
import { IconHome, IconClipboardList, IconBuildingStore, IconChartAreaLine } from '@tabler/icons';

// constant
const icons = { IconHome, IconClipboardList, IconBuildingStore, IconChartAreaLine };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const ocelot = {
    id: 'dashboard',
    title: 'DASHBOARD',
    type: 'group',
    children: [
        {
            id: 'overview',
            title: 'Portfolio Overview',
            type: 'item',
            url: '/overview',
            icon: icons.IconHome,
            breadcrumbs: false
        },
        {
            id: 'assets',
            title: 'Single Asset View',
            type: 'item',
            url: '/single-asset/overview',
            icon: icons.IconClipboardList,
            breadcrumbs: false
        },
        {
            id: 'market',
            title: 'ISO Market Conditions',
            type: 'item',
            url: '/market',
            icon: icons.IconBuildingStore,
            breadcrumbs: false
        }
    ]
};

export default ocelot;
