// material-ui
import { useTheme } from '@mui/material/styles';

// import logoDark from 'assets/images/logo-dark.svg';
// import logo from 'assets/images/logo.svg';
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */
import logo from 'assets/images/logo-white.svg';
import logoDark from 'assets/images/logo-dark.svg';
// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="caerus" width="182" />;
};

export default Logo;
